document.addEventListener('turbo:load', () => {
    "use strict"; // start of use strict

    // Auto open header dropdowns on hover for large screens
    if (window.innerWidth > 1400) {
        const dropdownIds = ['navbarDropdownCategory'];

        const handleMouseover = (dropdownId) => {
            const dropdown = document.getElementById(dropdownId);
            dropdown?.addEventListener('mouseover', () => {
                dropdown.click()
            });
        };

        dropdownIds.forEach(handleMouseover);
    }
})

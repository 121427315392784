window.increaseSystemStatisticCount = function(statisticKey) {
    const url = '/api/v1/system_statistics/increase_count'
    const data = {
        statistic_key: statisticKey
    }
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }
    fetch(url, options)
        .then(response => {
            if (response.ok) {
                console.log(`Increased system statistic count: ${statisticKey}`)
            }
        })
}

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
import '@hotwired/turbo-rails'
Turbo.session.drive = false

//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

import 'select2/dist/css/select2.css'

import '../controllers'
import '../src/main'
import '../src/cookies'
import '../src/utils'
import 'flowbite'

// Scroll top
import smoothscroll from 'smoothscroll-polyfill'
import '../src/scrolltop'
smoothscroll.polyfill()

// Example: Import a stylesheet in app/frontend/index.css
import '~/application.css'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import '@fortawesome/fontawesome-free'
library.add(fas, far, fab)

document.addEventListener('turbo:load', () => {
    $('.js-select2').select2({
        placeholder: 'Select an option',
        allowClear: false
    })
    $(document).on('select2:open', () => {
        document.querySelector('.select2-container--open .select2-search__field').focus()
    })

    $(document).ready(function () {
        $('.owl-carousel')?.owlCarousel({
            loop: false,
            rewind: true,
            nav: true,
            margin: 20,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 2
                },
                400: {
                    items: 3
                },
                600: {
                    items: 4
                },
                1000: {
                    items: 6
                }
            }
        })
    })
})

window.addEventListener('scroll', function () {
    const handleScroll = (target) => {
        window.scrollTo({
            top: target === 'top' ? 0 : document.body.scrollHeight,
            behavior: 'smooth',
        });
    };

    const scrollTopButton = document.getElementById('scrollTop');
    const scrollBottomButton = document.getElementById('scrollBottom');

    if (scrollTopButton) {
        scrollTopButton.addEventListener('click', () => handleScroll('top'));
    }

    if (scrollBottomButton) {
        scrollBottomButton.addEventListener('click', () => handleScroll('bottom'));
    }
});
